/**
 * @generated SignedSource<<f6e929f6512bd5f3ea73bf969dd2b585>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchSlidePreviewQuery$variables = {
  id: string;
};
export type SearchSlidePreviewQuery$data = {
  readonly eventById: {
    readonly id?: string;
    readonly syncedData?: {
      readonly segments: ReadonlyArray<{
        readonly startTime: number;
        readonly uri: string;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type SearchSlidePreviewQuery = {
  response: SearchSlidePreviewQuery$data;
  variables: SearchSlidePreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SyncedEventData",
      "kind": "LinkedField",
      "name": "syncedData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Segment",
          "kind": "LinkedField",
          "name": "segments",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uri",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuthorizedEvent",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchSlidePreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "eventById",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchSlidePreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "eventById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b5b42cb61815c384f9d539d60ae49976",
    "id": null,
    "metadata": {},
    "name": "SearchSlidePreviewQuery",
    "operationKind": "query",
    "text": "query SearchSlidePreviewQuery(\n  $id: ID!\n) {\n  eventById(id: $id) {\n    __typename\n    ... on AuthorizedEvent {\n      id\n      syncedData {\n        segments {\n          startTime\n          uri\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddafa7cb567421e338c2e175fdb9e4ac";

export default node;
