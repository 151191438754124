/**
 * @generated SignedSource<<d6acb75162a7c5ffea0bd848e00055f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemType = "EVENT" | "REALM" | "SERIES" | "%future added value";
export type TextAssetType = "CAPTION" | "SLIDE_TEXT" | "%future added value";
export type Filters = {
  end?: string | null | undefined;
  itemType?: ItemType | null | undefined;
  start?: string | null | undefined;
};
export type SearchQuery$variables = {
  filters: Filters;
  q: string;
};
export type SearchQuery$data = {
  readonly search: {
    readonly __typename: "EmptyQuery";
    readonly dummy: boolean | null | undefined;
  } | {
    readonly __typename: "SearchResults";
    readonly items: ReadonlyArray<{
      readonly __typename: string;
      readonly ancestorNames?: ReadonlyArray<string | null | undefined>;
      readonly audioOnly?: boolean;
      readonly created?: string;
      readonly creators?: ReadonlyArray<string>;
      readonly description?: string | null | undefined;
      readonly duration?: number;
      readonly endTime?: string | null | undefined;
      readonly hostRealms?: ReadonlyArray<{
        readonly path: string;
      }>;
      readonly id: string;
      readonly isLive?: boolean;
      readonly name?: string | null | undefined;
      readonly path?: string;
      readonly seriesId?: string | null | undefined;
      readonly seriesTitle?: string | null | undefined;
      readonly startTime?: string | null | undefined;
      readonly textMatches?: ReadonlyArray<{
        readonly duration: number;
        readonly highlights: ReadonlyArray<{
          readonly len: number;
          readonly start: number;
        }>;
        readonly start: number;
        readonly text: string;
        readonly ty: TextAssetType;
      }>;
      readonly thumbnail?: string | null | undefined;
      readonly thumbnails?: ReadonlyArray<{
        readonly audioOnly: boolean;
        readonly isLive: boolean;
        readonly thumbnail: string | null | undefined;
      }>;
      readonly title?: string;
    }>;
    readonly totalHits: number;
  } | {
    readonly __typename: "SearchUnavailable";
    readonly dummy: boolean | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserData">;
};
export type SearchQuery = {
  response: SearchQuery$data;
  variables: SearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "q"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dummy",
    "storageKey": null
  }
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "EmptyQuery",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "SearchUnavailable",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creators",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seriesTitle",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seriesId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLive",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "audioOnly",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "TextMatch",
  "kind": "LinkedField",
  "name": "textMatches",
  "plural": true,
  "selections": [
    (v21/*: any*/),
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ty",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ByteSpan",
      "kind": "LinkedField",
      "name": "highlights",
      "plural": true,
      "selections": [
        (v21/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "len",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ThumbnailInfo",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SearchSeries",
  "abstractKey": null
},
v24 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v20/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ancestorNames",
      "storageKey": null
    }
  ],
  "type": "SearchRealm",
  "abstractKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHits",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserData"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchRealm",
                        "kind": "LinkedField",
                        "name": "hostRealms",
                        "plural": true,
                        "selections": [
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/)
                    ],
                    "type": "SearchEvent",
                    "abstractKey": null
                  },
                  (v23/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/)
            ],
            "type": "SearchResults",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpload",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseStudio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseEditor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canCreateUserRealm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFindUnlisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userRole",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchRealm",
                        "kind": "LinkedField",
                        "name": "hostRealms",
                        "plural": true,
                        "selections": [
                          (v20/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/)
                    ],
                    "type": "SearchEvent",
                    "abstractKey": null
                  },
                  (v23/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/)
            ],
            "type": "SearchResults",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f99ee2dc2e3ccab37cca0a094945e718",
    "id": null,
    "metadata": {},
    "name": "SearchQuery",
    "operationKind": "query",
    "text": "query SearchQuery(\n  $q: String!\n  $filters: Filters!\n) {\n  ...UserData\n  search(query: $q, filters: $filters) {\n    __typename\n    ... on EmptyQuery {\n      dummy\n    }\n    ... on SearchUnavailable {\n      dummy\n    }\n    ... on SearchResults {\n      items {\n        id\n        __typename\n        ... on SearchEvent {\n          title\n          description\n          thumbnail\n          duration\n          creators\n          seriesTitle\n          seriesId\n          isLive\n          audioOnly\n          startTime\n          endTime\n          created\n          hostRealms {\n            path\n            id\n          }\n          textMatches {\n            start\n            duration\n            text\n            ty\n            highlights {\n              start\n              len\n            }\n          }\n        }\n        ... on SearchSeries {\n          title\n          description\n          thumbnails {\n            thumbnail\n            isLive\n            audioOnly\n          }\n        }\n        ... on SearchRealm {\n          name\n          path\n          ancestorNames\n        }\n      }\n      totalHits\n    }\n  }\n}\n\nfragment UserData on Query {\n  currentUser {\n    username\n    displayName\n    canUpload\n    canUseStudio\n    canUseEditor\n    canCreateUserRealm\n    canFindUnlisted\n    roles\n    userRole\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c36a91e8f8ecfa5801f63ccccef1206";

export default node;
